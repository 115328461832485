import { Components } from '@mui/material';

const MuiTabs: Components['MuiTabs'] = {
  styleOverrides: {
      indicator: ({theme}: any) => ({
        backgroundColor: theme.palette.primary.main,
        height: '4px',
    }),
  },
}

export default MuiTabs;

import './auth.css';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Email } from 'assets/svg/email-icon.svg';
import { ReactComponent as Password } from 'assets/svg/password-icon.svg';
import { AuthContext } from 'hooks/useAuth';
import LoginRequest from 'models/auth/loginRequest';
import { authAPI } from 'services/authAPI';
import Storage, { StorageKeys } from 'utils/Storage';
import { filledInputClasses, Stack, TextField, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import schema from './schema/LoginFormSchema';

export const LoginForm = () => {
  const { palette } = useTheme()
  const { setUser } = useContext(AuthContext)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const storage = new Storage();
  const [authError, setAuthError] = useState<string | null>(null);

  const onSubmit = async (data: LoginRequest) => {
    const { email, password } = data;
    try {
      const res = await authAPI.login({ email, password });
      setUser(res.data);
      storage.setItem(StorageKeys.Token, res.data.id);
      storage.setItem(StorageKeys.RoleId, res.data.roleId);
      storage.setItem(StorageKeys.UserName, res.data.firstName);

      navigate('/');

    } catch (error: any) {
      const authErrorMessage = error?.response?.status === 401 ?
        "Your email and password do not match. Please try again."
        : error.response?.status === 404 ? "User is not registered. Please signup"
        :"Something went wrong"
      setAuthError(authErrorMessage);
    }
  };

  const textFieldStyle: SxProps = {
    width: 1,
    [`& .${filledInputClasses.root}`]: {
      mt: 0,
      ':hover':{
        backgroundColor: palette.background.paper,
      },
      border: 0,
    },
  }

  return (
    <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="auth-title">All roads start here</h3>

      <Stack
        sx={{
          px: 1.5,
          py: 0.2,
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: 3.2,
          border: `1px solid ${palette.border.o40}`
        }}>
        <Email />

        <TextField
          {...register('email')}
          type='email'
          label='Email'
          sx={textFieldStyle}
        />
      </Stack>

      {errors.email && <span className='error-text'>{errors.email.message}</span>}

      <Stack
        sx={{
          px: 1.5,
          py: 0.2,
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: 3.2,
          border: `1px solid ${palette.border.o40}`
        }}>
        <Password />

        <TextField
          {...register('password')}
          type='password'
          label='Password'
          sx={textFieldStyle}
        />
      </Stack>

      {errors.password && <span className='error-text'>{errors.password.message}</span>}
      {authError && <span className='error-text'>{authError || 'Something went wrong'}</span>}
      <button type="submit">Login</button>
    </form>
  );
};


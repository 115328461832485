import { Components, filledInputClasses, inputLabelClasses, inputBaseClasses } from '@mui/material';

const MuiTextField: Components['MuiTextField'] = {
  defaultProps: {
    variant: 'filled',
    InputProps: {
      disableUnderline: true
    }
  },
  variants: [
    {
      props: { variant: "filled" },
      style: ({ theme }: any) => ({
        [`& .${filledInputClasses.root}`]: {
          borderRadius: 10,
          fontSize: 21,
          border: '1px solid',
          // ol[40]
          borderColor: '#DBE0D6',
          // background.paper
          backgroundColor: 'inherit',
          '&.Mui-focused': {
            // background.paper
            backgroundColor: 'rgba(0, 0, 0, 0.06)',

            '& .MuiInputBase-input:focus': {
              backgroundColor: 'transparent',
            },
          },
          '&.Mui-disabled': {
            backgroundColor: '#FFFFFF',
          },
          ':hover': {
            // background.default
            backgroundColor: '#F5F5F5',
          },
          '& .MuiInputBase-inputMultiline': {
            '::-webkit-scrollbar': {
              width: '10px'
            },
            '::-webkit-scrollbar-thumb': {
              background: theme.palette.primary.main,
              borderRadius: '4px',
              border: '2px solid #f1f1f1',
            }
          },
        },
        '& .MuiInputBase-root': {
          paddingLeft: '20px',
        },
        '& .MuiInputBase-input': {
          paddingLeft: '0px',
        },
        [`& .${inputLabelClasses.root}`]: {
          textTransform: 'uppercase',
          fontSize: 21,
          marginLeft: '8px',
          '&.Mui-focused': {
            // text.secondary
            color: '#666666',
          },
          [`&.${inputLabelClasses.shrink}`]: {
            fontSize: 18,
          },
        },
        '& .MuiSelect-icon': {
          top: 'calc(25%)'
        },
      })
    },
    {
      props: { variant: 'filled', size: 'small' },
      style: {
        '& .MuiSelect-select': { paddingTop: 0 },
        '& .MuiInputBase-root': {
          paddingTop: '8px',
        },
        '& .MuiSelect-icon': {
          top: 'calc(13%)'
        }
      }
    },
    {
      props: { variant: "standard" },
      style: ({ theme }: any) => ({
        [`& .${inputBaseClasses.root}`]: {
          backgroundColor: 'inherit',
          '&.Mui-focused': {
            backgroundColor: 'inherit',
          },
          '&.Mui-disabled': {
            backgroundColor: '#FFFFFF',
          },
          ':hover': {
            backgroundColor: '#F5F5F5',
          },
          '& .MuiInputBase-input': {
            '::placeholder': {
              color: theme.palette.text.primary,
              opacity: 1,
            },
          },
        },
      })
    },
  ]
}

export default MuiTextField

import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import Layout from './Layout';
import { AdvisorsPageLazy } from './pages/Advisors';
import { AdvisorPageLazy } from './pages/Advisor';
import Login from './pages/Login';
import { NotFound } from './pages/NotFound';
import RequestResetPassword from './pages/RequestResetPassword';
import ResetPassword from './pages/ResetPassword';
import Signup from './pages/Signup';
import { SuppliersPageLazy } from './pages/Suppliers';
import { SupplierPageLazy } from './pages/Supplier';
import { UsersPageLazy } from './pages/Users';
import { UserPageLazy } from './pages/User';
import ProtectedRoute from './ProtectedRoute';
import { ServicePageLazy } from './pages/Service';
import { useRoles } from './hooks/useRoles';
import { TasksPageLazy } from './pages/Tasks';
import { TripPageLazy } from './pages/Trip';
import { TripsPageLazy } from './pages/Trips';
import { TemplatesPageLazy } from './pages/Templates';
import { TemplatesEditPageLazy } from './pages/TemplatesEdit';
import { TemplatesAddPageLazy } from './pages/TemplatesAdd';
import { AnalyticsPageLazy } from './pages/Analytics';
import { TeamWorkLoadPageLazy } from './pages/TeamWorkload';
import AuthProvider from './hooks/useAuth';
import { LoadingPage } from './pages/LoadingPage';
import { ItineraryNotesPageLazy } from 'pages/ItineraryNotes';

function App() {
  const roles = useRoles();

  if (!roles.length) {
    return <LoadingPage />;
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AuthProvider />}>
        <Route path="signup" element={<Signup />} />
        <Route path="login" element={<Login />} />
        <Route path="request-reset-password" element={<RequestResetPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />

        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<TripsPageLazy />} />
            <Route path="analytics" element={<AnalyticsPageLazy />} />
            <Route path="team-workload" element={<TeamWorkLoadPageLazy />} />
            <Route element={<ProtectedRoute rolesAllowed={['Super Admin', 'Sales Manager']} />}>
              <Route path="users">
                <Route index element={<UsersPageLazy />} />
                <Route path="add" element={<UserPageLazy />} />
                <Route path=":uuid/edit" element={<UserPageLazy />} />
              </Route>
            </Route>
            <Route path="tasks" element={<TasksPageLazy />} />
            <Route
              element={
                <ProtectedRoute
                  rolesAllowed={[
                    'Super Admin',
                    'Sales Manager',
                    'Travel Consultant',
                    'Travel Coordinator',
                  ]}
                />
              }
            >
              <Route path="advisors">
                <Route index element={<AdvisorsPageLazy />} />
                <Route path="add" element={<AdvisorPageLazy />} />
                <Route path=":uuid/edit" element={<AdvisorPageLazy />} />
              </Route>
            </Route>
            <Route path="trips">
              <Route path="add" element={<TripPageLazy />} />
              <Route path=":uuid" element={<TripPageLazy />} />
            </Route>

            <Route
              element={
                <ProtectedRoute
                  rolesAllowed={[
                    'Super Admin',
                    'Sales Manager',
                    'Travel Consultant',
                    'Travel Coordinator',
                  ]}
                />
              }
            >
              <Route path="suppliers">
                <Route index element={<SuppliersPageLazy />} />
                <Route path="add" element={<SupplierPageLazy />} />
                <Route path=":uuid/edit" element={<SupplierPageLazy />} />
                <Route path=":uuid/service/add" element={<ServicePageLazy />} />
                <Route path=":uuid/service/edit/:uuid" element={<ServicePageLazy />} />
              </Route>
            </Route>

            <Route path="templates">
              <Route index element={<TemplatesPageLazy />} />
              <Route path="add" element={<TemplatesAddPageLazy />} />
              <Route path=":uuid/edit" element={<TemplatesEditPageLazy />} />
            </Route>

            <Route path="itinerary-notes">
              <Route index element={<ItineraryNotesPageLazy />} />
            </Route>
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>
    )
  );

  return (
    <RouterProvider
      router={router}
      fallbackElement={<LoadingPage />}
      future={{ v7_startTransition: true }}
    />
  );
}

export default App;

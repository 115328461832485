import React from 'react';
import { Menu, MenuItem, ListItemIcon, Typography, Divider } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

interface MenuItem {
  icon: React.ReactElement<SvgIconProps>;
  text: string;
  onClick: () => void;
}

interface MenuListProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  items: MenuItem[];
  handleClose: (event: React.MouseEvent<HTMLElement>) => void;
}

export const PaperMenuList: React.FC<MenuListProps> = ({ handleClose, anchorEl, open, items }) => {
  return (
    <Menu
      sx={{
        py: '14px',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            py: '7px',
            borderRadius: '10px',
            border: '1px solid #DBE0D6',
            overflow: 'visible',
            mt: 1.5,
          },
        },
      }}
      transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {items.map((item, index) => (
        <div key={index}>
          <MenuItem
            key={index}
            sx={{
              py: '9px',
              px: '20px',
            }} 
            onClick={item.onClick}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <Typography variant='subtitle2' component={'span'}>{item.text}</Typography>
          </MenuItem>
          {index !== items.length - 1 ? <Divider sx={{ mx: '20px', my: '10px', color: '#DBE0D6' }} /> : null}
        </div>
      ))}
    </Menu>
  );
};
import { instance } from "./index";

export const authAPI = {
  login: async ({ email, password }: any) => {
    return instance.post(`/auth/login`, {
      email,
      password
    })
    .then(res => res);
  },
  signup: async ({ email, password, }: any) => {
    return instance.post(`/auth/signUp`, {
      email,
      password,
    })
    .then(res => res);
  },
  invite: async ({ email, firstName, lastName, roleId }: any) => {
    return instance.post(`/auth/invite`, {
      email,
      firstName,
      lastName,
      roleId,
    })
    .then(res => res);
  },
  passwordResetRequest: async ({ email }: any) => {
    return instance.post(`/auth/request-password-reset`, {
      email,
    })
    .then(res => res);
  },
  passwordReset: async ({ token, password, }: any) => {
    return instance.post(`/auth/reset-password`, {
      token,
      password,
    })
    .then(res => res);
  },
}

import { styled } from '@mui/material/styles';
import { customColors } from 'theme/theme';

const SideBarWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  width: 80,
  height: '100vh',
  padding: theme.spacing(0.875, 1.375, 3.125, 1.375), // Рассчитывает с учетом spacing 8
  background: customColors.background.ol,
  boxSizing: 'border-box',
  zIndex: theme.zIndex.appBar,
  '.logo svg': {
    width: '100%',
  }
}));

const MenuItems = styled('nav')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  marginTop: '52px',
  gap: '10px',
});

const IconWrap = styled('span')(({ theme, active }) => ({
  paddingTop: '6px',
  paddingBottom: '6px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 60,
  height: 76,
  borderRadius: 10,
  textAlign: 'center',
  background: active ? theme.palette.text.white : 'none',
  '&:hover': {
    background: theme.palette.customColors.ol[70],
    'svg path': {
      fill: theme.palette.text.white,
    },
    '.MuiTypography-sidebarLabel': { 
      color: theme.palette.text.white,
    }
  },
  'svg': { 
    width: 40,
    height: 40,
  },
  'svg path': {
    fill: active ? theme.palette.primary.main : '',
  },
  '.MuiTypography-sidebarLabel': { 
    marginTop: 'auto',
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: active ? theme.palette.text.secondary : theme.palette.text.white,
  }
}));

const LogoutMenu = styled('button')({
  position: 'absolute',
  bottom: 40,
  border: 0,
  outline: 0,
  background: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
  '&:focus': {
    outline: 0,
    border: 0,
    background: 'none',
  }
});

export { SideBarWrapper, MenuItems, IconWrap, LogoutMenu };

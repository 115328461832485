import { StorageKeys, StorageProps } from "./types";

export default class Storage<T extends StorageKeys> {
    private readonly storage: StorageProps;

    public constructor(getStorage = (): StorageProps => window.localStorage) {
        this.storage = getStorage();
    }

    public getItem(key: T): string | null {
        return this.storage.getItem(key);
    }

    public setItem(key: T, value: string): void {
        this.storage.setItem(key, value);
    }
    public removeItem(key: T): void {
        this.storage.removeItem(key);
    }
    public removeItems(keys: Array<T>): void {
        keys.forEach((key) => this.removeItem(key));
    }
}
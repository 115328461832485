import { Components } from '@mui/material';

const MuiTab: Components['MuiTab'] = {
  styleOverrides: {
    root: ({ theme, ownerState }: any) => ({
      marginRight: '30px',
      paddingLeft: 0,
      paddingRight: 0,
      minWidth: '30px',
      color: theme.palette.text.disabled,
      fontFamily: 'Barlow Condensed',
      fontSize: 26,
      fontWeight: 700,
      lineHeight: '36.4px',
      letterSpacing: '0.04em',
      '&.Mui-selected': {
        color: '#000',
      },
      '&:hover': {
        color: theme.palette.text.secondary,
      },
      '&:hover::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '4px',
        backgroundColor: '#A4B199',
        transition: 'opacity 0.3s',
      },
    }),
  },
};

export default MuiTab;

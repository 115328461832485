import { Components } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    pure: true;
  }
}

const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
  },
  variants: [
    {
      props: { variant: 'pure' },
      style: {
        padding: '0px !important',
        border: 'none',
        letterSpacing: '0.04em',
        fontWeight: 700,
        fontFamily: 'Barlow Condensed',
        '&.Mui-disabled': {
          opacity: 1,
          border: `1px solid transparent`,
          backgroundColor: 'transparent',
        },
      },
    },
  ],
  styleOverrides: {
    root: ({ theme, ownerState }: any) => ({
      border: `1px solid ${theme.palette[ownerState.color || 'primary'].main}`,
      borderRadius: '10px',
      textTransform: 'uppercase',
      padding: '4px 20px',
      boxShadow: 'none',
      // '& .MuiIconButton-root': {
      //   '&:hover': {
      //     backgroundColor: 'rgba(164, 177, 153, 0.1)',
      //     borderRadius: '10px',
      //   }
      // },
      '& .MuiButton-startIcon': {
        marginLeft: 0,
        marginRight: '12px',
      },
      '&.Mui-disabled': {
        opacity: 0.5,
        border: `1px solid ${
          theme.palette[ownerState.color || 'primary'].main
        }`,
        backgroundColor: theme.palette[ownerState.color || 'primary'].main,
      },
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    text: {
      border: 'none',
      letterSpacing: '0.04em',
      fontWeight: 700,
      fontFamily: 'Barlow Condensed',
      '&.Mui-disabled': {
        opacity: 1,
        border: `1px solid transparent`,
        backgroundColor: 'transparent',
      },
    },

    sizeSmall: {
      fontSize: '13px',
      lineHeight: '18.2px',
      padding: '6px 10px',
    },
    sizeMedium: {
      fontSize: '16px',
      lineHeight: '22.4px',
      padding: '4px 20px',
    },
    sizeLarge: {
      fontSize: '21px',
      lineHeight: '29.4px',
      padding: '11.5px 20px',
    },
    contained: {
      color: '#FFFFFF',
      '&.Mui-disabled': {
        color: '#FFFFFF',
      },
      '&:hover': {
        opacity: 0.9,
      },
    },
    containedPrimary: ({theme}: any) => ({
      '&:hover': {
        backgroundColor: '#ADB9A3',
        borderColor: theme.palette.primary.light
      },
    }),
    outlined: ({ theme, ownerState }: any) => ({
      '&.Mui-disabled': {
        color: theme.palette[ownerState.color || 'primary'].main,
      },
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: theme.palette[ownerState.color || 'primary'].main,
      },
    }),
  },
};

export default MuiButton;

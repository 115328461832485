import { Components } from '@mui/material';

const MuiDialog: Components['MuiDialog'] = {
  styleOverrides: {
    root: {
      '.MuiDialogTitle-root': {
        fontFamily: 'Barlow Condensed',
        fontSize: 26,
        fontWeight: 700,
        lineHeight: '36.4px',
        letterSpacing: '0.04em',
      }
    },
  },
}

export default MuiDialog;

import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',

  main: {
    flexGrow: 1,
    padding: `${theme.spacing(3.75)} ${theme.spacing(5)}`,
    paddingBottom: '60px',
    marginLeft: theme.spacing(10),
    width: `calc(100% - ${theme.spacing(10)})`,
    display: 'flex',
    flexDirection: 'column',
  }
}));

import React, { createContext, FC, useEffect, useMemo, useState } from 'react';

import { useOutlet } from 'react-router-dom';
import { LoadingPage } from 'pages/LoadingPage';
import instance from 'services';
import { AxiosResponse } from 'axios';
import { User } from 'services/usersAPI';

interface AuthContextProps {
  user: User | null
  setUser: (user: User | null) => void
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  setUser: () => {}
});

const AuthProvider: FC = () => {
  const outlet = useOutlet();

  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const validate = () => {
    setLoading(true)
    instance.get<null, AxiosResponse<User | null>>('/auth/session'
    , {headers: {
          'Accept': '*/*',
          'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate',
          'Pragma': 'no-cache',
        }})
      .then((response) => setUser(response.data))
      .catch(() => setUser(null))
      .finally(() => setLoading(false))
  }

  const value = useMemo(() => ({
    user,
    setUser,
  }), [user])

  useEffect(() => {
    validate()
  }, [])

  return (
    <AuthContext.Provider value={value}>
      {loading ? <LoadingPage /> : outlet}
    </AuthContext.Provider>
  );
};

export default AuthProvider

import { Components } from '@mui/material';

const MuiTable: Components['MuiTable'] = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      borderCollapse: 'separate',
      borderSpacing: '0 10px',
    }),
  },
};

export default MuiTable;

import React from 'react';
import { AuthTemplate } from 'components/Auth/AuthTemplate';
import { LoginForm } from 'components/Auth/LoginForm';

const Login = () => {
  return (
    <AuthTemplate
      footer={{
        title: 'Forgot password?',
        buttonText: 'reset password',
        buttonUrl: '/request-reset-password',
      }}
    >
      <LoginForm />
    </AuthTemplate>
  );
};
export default Login;

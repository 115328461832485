import { Components } from '@mui/material';

const MuiCheckbox: Components['MuiCheckbox'] = {
  styleOverrides: {
    root: ({theme}: any) => ({
      boder: '1px solid #000',
      color: 'white',
      '&.Mui-checked': {
        color: theme.palette.primary.main,
      },
    }),
  },
}

export default MuiCheckbox

import { alpha, Components } from '@mui/material';

const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: {
      '*::-webkit-scrollbar': {
        width: '15px',
      },
      '*::-webkit-scrollbar-track': {
        background: '#FAFAFA',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#556447',
      },
      '*::-webkit-scrollbar-thumb:hover': {
        backgroundColor: alpha('#556447', 0.8),
      },
  },
}

export default MuiCssBaseline

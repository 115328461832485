import React from 'react';
import { AuthTemplate } from 'components/Auth/AuthTemplate';
import { SetPasswordForm } from 'components/Auth/SetPassword';

const ResetPassword = () => {
  return (
    <AuthTemplate
      footer={{
        title: 'Back to login?',
        buttonText: 'login',
        buttonUrl: '/login',
      }}
    >
      <SetPasswordForm />
    </AuthTemplate>
  );
};

export default ResetPassword;

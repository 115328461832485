export enum ReactQueryKeys {
  TRIPS = 'trips',
  TRIP_NOTES = 'tripNotes',
  TRIP_CLIENTS = 'tripClients',
  SEARCH_SERVICES = 'SEARCH_SERVICES',
  SEARCH_SERVICES_NOTES = 'SEARCH_SERVICES_NOTES',
  SEARCH_ITINERARY_NOTES = 'SEARCH_ITINERARY_NOTES',
  UPDATE_NOTES = 'UPDATE_NOTES',
  DUBLICATE_NOTES = 'DUBLICATE_NOTES',
  DELETE_NOTES = 'DELETE_NOTES',
  SEARCH_SCHEDULE = 'SEARCH_SCHEDULE',
  GET_SHEDULE_PLANS = 'GET_SHEDULE_PLANS',
  IMPORT_SCHEDULE_PLAN = 'IMPORT_SCHEDULE_PLAN',
  CREATE_SCHEDULE_PLAN = 'CREATE_SCHEDULE_PLAN',
  UPDATE_SCHEDULE_PLAN = 'UPDATE_SCHEDULE_PLAN',
  DELETE_SCHEDULE_PLAN = 'DELETE_SCHEDULE_PLAN',
  UPDATE_SCHEDULES = 'UPDATE_SCHEDULES',
  DUBLICATE_SCHEDULES = 'DUBLICATE_SCHEDULES',
  DELETE_SCHEDULES = 'DELETE_SCHEDULES',
  SCHEDULE_TEMPLATES = 'scheduleTemplates',
  FINANCE = 'finance',
  TEMPLATES = 'templates',
  SUPPLIERS = 'suppliers',
  SUPPLIER = 'supplier',
  SERVICES = 'services',
  SERVICE = 'service',
  SCHEDULES = 'schedules',
  SCHEDULES_HISTORY = 'schedules_history',
  ROLES = 'roles',
  ANALYTICS = 'analytics',
  USERS = 'users',
  ADVISORS = 'advisors',
  CLIENTS = 'clients',
  LOCATIONS = 'locations',
  ITINERARY_NOTES = 'itinerary_notes',
}

import { Components, linearProgressClasses } from '@mui/material';

const MuiLinearProgress: Components['MuiLinearProgress'] = {
  variants: [
    {
      props: {},
      style: {
        // customColors.ol[70],
        backgroundColor: '#BFC8B8',
        [`& .${linearProgressClasses.bar}`]: {
          // customColors.ol[100],
          backgroundColor: '#DBE0D6',
        },
      }
    },
  ]
}

export default MuiLinearProgress

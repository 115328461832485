import React from 'react';
import { AuthTemplate } from 'components/Auth/AuthTemplate';
import { SignupForm } from 'components/Auth/SignupForm';

const Signup = () => {
  return (
    <AuthTemplate
      footer={{
        title: 'Do you already have an account?',
        buttonText: 'login',
        buttonUrl: '/login',
      }}
    >
      <SignupForm />
    </AuthTemplate>
  );
};

export default Signup;

export enum StorageKeys {
    Token = 'token',
    RoleId = 'role',
    UserName = 'user',
}

export interface StorageProps {
    getItem: (key: StorageKeys) => string | null;
    setItem: (key: StorageKeys, value: string) => void;
    removeItem: (key: string) => void;
}
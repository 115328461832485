import { Components } from '@mui/material';

const MuiMenuItem: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      '&:hover': {
        backgroundColor: '#EDEFEB',
      },
    },
  },
}

export default MuiMenuItem;

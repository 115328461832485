import React, { useContext, useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Advisors } from 'assets/svg/advisors.svg';
import { ReactComponent as Trip } from 'assets/svg/trip-icon.svg';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import { ReactComponent as Templates } from 'assets/svg/templates.svg';
import { ReactComponent as Suppliers } from 'assets/svg/suppliers.svg';
import { ReactComponent as Analytics } from 'assets/svg/analytics.svg';
import { ReactComponent as Settings } from 'assets/svg/settings-icon.svg';
import { ReactComponent as SettingsUser } from 'assets/svg/user-icon-sm.svg';
import { ReactComponent as SettingsLogout } from 'assets/svg/logout-icon-sm.svg';
import { adminUser, salesManager, travelConsultantRole, travelCoordinatorRole } from 'utils/helpers';
import getAuthUserRole from 'utils/getAuthUserRole';

import SidebarItem from './SideBarItem';
import { MenuItems, LogoutMenu, SideBarWrapper, IconWrap } from './styles';
import { useRoles } from 'hooks/useRoles';
import { AuthContext } from 'hooks/useAuth';
import { Typography } from '@mui/material';
import { PaperMenuList } from 'ui-kit/PaperMenuList';

const SideBar = () => {
  const authUserRole = getAuthUserRole();
  const roles = useRoles();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorSettingsEl, setAnchorSettingsEl] = useState<null | HTMLElement>(null);
  const [openSettings, setOpenSettings] = useState(false);
  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSettingsEl(event.currentTarget);
    setOpenSettings(true);
  };
  const handleSettingsClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorSettingsEl(null);
    setOpenSettings(false);
  };

  const { setUser } = useContext(AuthContext)

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
    setUser(null)
  };

  const isAllowedSuppliers = () => {
    return adminUser(roles, authUserRole)
      || salesManager(roles, authUserRole)
      || travelConsultantRole(roles, authUserRole)
      || travelCoordinatorRole(roles, authUserRole)
  }

  const isAllowedAdvisors = () => {
    return adminUser(roles, authUserRole)
      || salesManager(roles, authUserRole)
      || travelCoordinatorRole(roles, authUserRole)
  }

  const showUsers = adminUser(roles, authUserRole) ||
    salesManager(roles, authUserRole);

  const settingsItems = [
    { icon: <SettingsUser />, text: 'Users', onClick: () => navigate('/users') },
    { icon: <SettingsLogout />, text: 'Logout', onClick: handleLogout },
  ];

  return (
    <SideBarWrapper>
      <Link className="logo" to="/">
        <Logo />
      </Link>
      <MenuItems>
        <SidebarItem
          to="/"
          active={location.pathname === '/' || location.pathname.indexOf('/tasks') > -1 || location.pathname.indexOf('/trips') > -1}
          label={'Trips'}
        >
          <Trip />
        </SidebarItem>

        {isAllowedSuppliers() && <SidebarItem
          to="/suppliers"
          active={
            location.pathname.indexOf('/suppliers') > -1
          }
          label={'Suppliers'}
        >
          <Suppliers />
        </SidebarItem>}

        {isAllowedSuppliers() && <SidebarItem
          to="/templates"
          active={
            location.pathname.indexOf('/templates') > -1 || location.pathname.indexOf('/itinerary-notes') > -1
          }
          label={'Templates'}
        >
          <Templates />
        </SidebarItem>}

        {isAllowedAdvisors() && <SidebarItem
          to="/advisors"
          active={location.pathname.indexOf('/advisors') > -1}
          label={'Advisors'}
        >
          <Advisors />
        </SidebarItem>}

        <SidebarItem
          to="/analytics"
          active={location.pathname.indexOf('/analytics') > -1 || location.pathname.indexOf('/team-workload') > -1}
          label={'Analytics'}
        >
          <Analytics />
        </SidebarItem>
      </MenuItems>
      <LogoutMenu
        onClick={handleSettingsClick}
        aria-controls={openSettings ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openSettings ? 'true' : undefined}
      >
        <IconWrap>
          <Settings />
          <Typography variant='sidebarLabel' component='span'>Settings</Typography>
        </IconWrap>
        <PaperMenuList
          anchorEl={anchorSettingsEl}
          open={openSettings}
          handleClose={handleSettingsClose}
          items={showUsers ? settingsItems : settingsItems.slice(1)}
        />
      </LogoutMenu>
    </SideBarWrapper>
  );
};

export default SideBar;

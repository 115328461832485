import { Components } from '@mui/material';

const MuiTooltip: Components['MuiTooltip'] = {
  styleOverrides: {
    tooltip: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: '0.03rem',
      lineHeight: '140%',
      padding: '20px',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
    },
    arrow: {
      color: '#fff',
    }
  },
}

export default MuiTooltip;
